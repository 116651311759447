<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">
                  {{ $t('MENU.SALARY.CALC') }}
                </h3>
              </div>
              <div class="card-button">
                <v-btn to="/salary_payrol/create" color="primary" small>
                  {{ $t('MENU.SALARY.CALC') }} +
                </v-btn>
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="list"
                :loading="isLoading"
                no-data-text="Malumot kiritilmagan"
                :items-per-page="10"
                hide-default-footer
                class="row-pointer"
                single-select
                @click:row="rowClick"
                item-key="id"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <div style="text-align: right !important">
                    <action :index="item"></action>
                  </div> </template
              ></v-data-table>
            </div>
            <div class="text-left my-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="10"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCount.count / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import action from './Action'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  components: { action },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      perPage: 10,
      currentPage: 1
    }
  },
  created() {
    // this.$store.dispatch("kontragentContractAktSverka");
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/salary_payrol/details/' + item.id + '/')
        row.select(false)
      }
    },
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,

          query: { page: value }
        })
      }
      this.$store.dispatch('getPayrolList', value)
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: this.$t('TABLE_HEADER.DATE'),
          value: 'oper_date'
        },
        {
          text: this.$t('TABLE_HEADER.NAME'),
          value: 'name'
        },
        {
          text: this.$t('TABLE_HEADER.CREATE_DATE'),
          value: 'created_at'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    list() {
      const data = this.$store.state.requests.payrolList.results
      if (data !== undefined) {
        data.forEach((element, index) => {
          element.index = index + 1
        })
      }
      return data
    },
    getCount() {
      return this.$store.state.requests.payrolList
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.SALARY.TITLE') },
      { title: this.$t('MENU.SALARY.CALC') }
    ])
  }
}
</script>
